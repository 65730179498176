import React from 'react'

import { combineClasses } from '~/util'

import styles from './Titles.module.scss'

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * Allows you to specify the HTML element to render. By default, an <h?> tag
   * is used with the heading level specified by the `level` prop.
   */
  as?: React.ElementType
  /**
   * The heading level to render (ex. h1, h2, etc). If undefined, then a default
   * is configured for each of the title sizes (ex. XLTitle = 1). If level is
   * any other falsy value, then a `<span>` is rendered instead of an `<h?>` tag.
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6 | false
}

function BaseTitle({ as: Tag = 'h1', level, ...props }: TitleProps) {
  return <Tag {...props} />
}

/**
 * Render an H1 (or change the element with the `as` prop)
 */
export function XLTitle({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.XLTitle, className)}
      as="h1"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H2 (or change the element with the `as` prop)
 */
export function Title({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.Title, className)}
      as="h2"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H3 (or change the element with the `as` prop)
 */
export function SubTitle({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.SubTitle, className)}
      as="h3"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H4 (or change the element with the `as` prop)
 */
export function ItemName({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.ItemName, className)}
      as="h4"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H5 (or change the element with the `as` prop)
 */
export function SmallTitle({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.SmallTitle, className)}
      as="h5"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H6 (or change the element with the `as` prop)
 */
export function MiniTitle({ children, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.MiniTitle, className)}
      as="h6"
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}
