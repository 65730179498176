import {
  API_Quote,
  FeeType,
  FloorPlan,
  ID,
  QuoteCategory,
  Unit,
} from '~/service'
import { TourState } from './store'
import { removeNulls } from '~/util'

const filterCategoryItems = (items: API_Quote[], type: FeeType, unit: Unit) => {
  if (!unit || !unit.quoteCategoryItemIds) {
    return []
  }
  return items.filter(
    (i) =>
      i.feeType === type &&
      Array.isArray(unit.quoteCategoryItemIds) &&
      unit.quoteCategoryItemIds.indexOf(i.id) > -1
  )
}

const findCategoryItemsByFeeType = (
  quoteCategories: QuoteCategory[] | undefined,
  type: FeeType,
  unit: Unit
) => {
  return quoteCategories?.flatMap((c) =>
    filterCategoryItems(c.items, type, unit)
  )
}

export const select = {
  initialized(state: TourState) {
    return state.initialized
  },

  notices(state: TourState) {
    return state.notices
  },

  uuid(state: TourState) {
    return state.guestCardUuid
  },

  guestCard(state: TourState) {
    return state.data?.guestCard
  },

  community(state: TourState) {
    return state.data?.community
  },

  /**
   * Select all of the amenity objects in the community.amenities
   * property and merge in their relationships. This list will
   * include all amenities, including special ones like the
   * leasing office. In most cases you will want to use
   * the `communityAmenities` or one of the
   * special amenity type selectors like `communityEntrances`.
   */
  // TODO Remove this
  amenitiesWithDetails(state: TourState) {
    // return select.community(state).amenities;
    return select.community(state)?.amenities?.map((a) => ({
      ...a,
      // Merge in floorPlate data for ease of use.
      floorPlate: select.floorPlate(state, a.floorPlateId),
      // Merge in special type details
      specialType: a.specialTypeId
        ? select.communityAmenitySpecialType(state, a.specialTypeId)
        : null,
    }))
  },

  communityAmenitiesFromGuestCardIds(state: TourState) {
    let { amenities } = select.community(state) || {}
    let { amenityIds } = select.guestCard(state) || {}
    let result =
      amenities?.filter((amenity) => amenityIds?.includes(amenity.id)) || []
    return result
  },

  /**
   * Get an amenity special type by its id.
   */
  communityAmenitySpecialType(state: TourState, id: ID) {
    const community = select.community(state)
    return community?.amenitySpecialTypes?.find((t) => t.id === id)
  },

  communityFeature(state: TourState, id: ID) {
    const community = select.community(state)
    return community?.features?.find((f) => f.id === id)
  },

  communityConfig(state: TourState) {
    // Return an empty config object if none exists so that
    // other selectors don't have to check for it's existance.
    return state.data?.config
  },

  /** @param {object} state */
  communityHasChat(state: TourState) {
    const config = select.communityConfig(state)
    return !!config?.pureChatClientId || !!config?.chatBotUrl
  },

  /**
   * Determine whether or not to show unit pricing for this community.
   */
  showUnitPrices(state: TourState) {
    const config = select.communityConfig(state)
    const isVariableRentEnabled = config?.isVariableRentEnabled
    const hasQuotes = select.quotes(state).length > 0
    // We can show unit prices if:
    // 1) Variable rent is disabled
    // 2) Variable rent is enabled but the user has no quotes.
    return !isVariableRentEnabled || (isVariableRentEnabled && !hasQuotes)
  },

  userHasSignedSelfTourAgreement(state: TourState) {
    return state.userHasSignedSelfTourAgreement
  },

  selfTourRequiresAgreement(state: TourState) {
    const config = select.communityConfig(state)
    return !!config?.selfTourAgreementText
  },

  shouldUserSignSelfTourAgreement(state: TourState) {
    return (
      select.selfTourRequiresAgreement(state) &&
      !select.userHasSignedSelfTourAgreement(state)
    )
  },

  /**
   * Determine if the user is checked in
   * (ie. has completed the check in questions and steps).
   */
  checkedIn(state: TourState) {
    const gc = select.guestCard(state)
    return (
      !!gc?.earliestMoveIn &&
      !!gc?.latestMoveIn &&
      !!gc?.maxPrice &&
      !!(gc?.bedBathIds?.length > 0)
    )
  },

  /**
   * gets a specific floor plan based on uuid.
   */
  floorPlan(state: TourState, id: ID) {
    return select.floorPlans(state).find((f) => f.id === id)
  },

  allFeaturesForUnitsByName(state: TourState) {
    const units = select.community(state)?.units || []
    const allUnitFeatures: string[] = []
    units.forEach((unit) => {
      unit.features.forEach((feature) => {
        if (feature.name && !allUnitFeatures.includes(feature.name))
          allUnitFeatures.push(feature.name)
      })
    })
    return allUnitFeatures
  },

  /**
   * Get the floor plans for a community.
   */
  floorPlans(
    state: TourState,
    filters: {
      bedrooms: number | undefined
      bathrooms: number | undefined
      price: number | undefined
      earliestMoveIn: Date | undefined
      latestMoveIn: Date | undefined
      features: string[] | undefined
      floor: number | undefined
    } = {} as any
  ) {
    const units = select.community(state)?.units || []
    const floorPlans = select.community(state)?.floorPlans

    return (
      floorPlans?.reduce((acc: (FloorPlan & { units: Unit[] })[], fp) => {
        // Filter Floor Plans
        if (filters) {
          if (
            fp.bedrooms != null &&
            filters.bedrooms &&
            filters.bedrooms > fp.bedrooms
          )
            return acc
          if (
            fp.bathrooms != null &&
            filters.bathrooms &&
            filters.bathrooms > fp.bathrooms
          )
            return acc
        }
        // Filter Units
        const matchingUnits = units?.filter((unit) => {
          let unitFeatureNames = unit?.features
            ?.map((feature) => feature.name)
            .filter(removeNulls)

          if (unit.floorPlanId === fp.id) {
            if (filters) {
              if (
                unit.price != null &&
                filters.price &&
                filters.price < unit.price
              )
                return false

              if (
                unit.availableTime != null &&
                filters.earliestMoveIn &&
                filters.latestMoveIn &&
                !(
                  filters.earliestMoveIn.getTime() <=
                    new Date(unit.availableTime).getTime() &&
                  filters.latestMoveIn.getTime() >=
                    new Date(unit.availableTime).getTime()
                )
              )
                return false

              if (
                filters.features &&
                !unitFeatureNames.some((feature) =>
                  filters.features!.includes(feature)
                )
              ) {
                return false
              }

              if (filters.floor && !(filters.floor === unit.floor)) return false

              return true
            } else {
              return true
            }
          } else {
            return false
          }
        })

        if (matchingUnits.length > 0) acc.push({ ...fp, units: matchingUnits })

        return acc
      }, []) || []
    )
  },

  floorPlate(state: TourState, floorPlateId: ID | undefined) {
    return select
      .community(state)
      ?.floorPlates?.find((f) => f.id === floorPlateId)
  },

  leaseTerm(state: TourState, id: ID) {
    return select.community(state)?.leaseTerms?.find((term) => term.id === id)
  },

  quotes(state: TourState) {
    let quotes = select.guestCard(state)?.quotes || []
    let sortedQuotes = quotes.slice()?.sort((a, b) => {
      const aExp = a?.expirationDate
      const bExp = b?.expirationDate
      if (aExp == null && bExp != null) return 1
      else if (aExp != null && bExp == null) return -1
      else if (aExp != null && bExp != null) {
        return new Date(bExp).getTime() - new Date(aExp).getTime()
      }
      return 0
    })
    return sortedQuotes
  },

  quote(state: TourState, id: ID) {
    return select.quotes(state).find((quote) => quote.id === id)
  },

  quotesForUnit(state: TourState, unitId: ID | undefined) {
    return select.quotes(state).filter((quote) => quote.unitId === unitId)
  },

  quoteMonthlyCharges(state: TourState, unit: Unit) {
    const community = select.community(state)
    return findCategoryItemsByFeeType(
      community?.quoteCategories,
      'MONTHLY',
      unit
    )
  },

  quoteRequiredCharges(state: TourState, unit: Unit) {
    const community = select.community(state)
    return findCategoryItemsByFeeType(
      community?.quoteCategories,
      'REQUIRED',
      unit
    )
  },

  quoteAdditionalCharges(state: TourState, unit: Unit) {
    const community = select.community(state)
    return community?.quoteCategories
      ?.map((category) => ({
        ...category,
        items: filterCategoryItems(category.items, 'NON_REQUIRED', unit),
      }))
      .sort((a, b) => {
        const scoreItem = (item: typeof a) => {
          if (item.name.indexOf('Application') > -1) {
            return 3
          } else if (item.name.indexOf('Pet') > -1) {
            return 2
          } else if (item.name.indexOf('Additional') > -1) {
            return 1
          } else {
            return 0
          }
        }

        return scoreItem(b) - scoreItem(a)
      })
  },
}
