import React from 'react'
import Truncate from 'react-truncate-html'

import { combineClasses } from '~/util'

import { ItemName } from '../../titles'
import { Label } from '../../labels'
import { Image } from '../../image'
import { Card, CardProps } from '../Card'

import styles from './AmenityCard.module.scss'
import { Nilable } from 'tsdef'
import { Lock } from '~/service'
import { DoorCodeTimer } from '~/components'

interface AmenityCardProps extends CardProps {
  /**
   *The marker label associating this card with a map marker (something like 'A').
   */
  label: string
  /**
   * The name of the amenity.
   */
  name: string
  imageUrl: Nilable<string>
  description: Nilable<string>
  lock: Nilable<Lock>
  onUnlockError: (label: string, error: unknown) => void
}

/**
 * Displays a summary of an amenity.
 */
export function AmenityCard({
  label,
  name,
  imageUrl,
  description,
  lock,
  onUnlockError,
  className,
  ...rest
}: AmenityCardProps) {
  let cardRef = React.useRef(null)
  return (
    <Card
      ref={cardRef}
      className={combineClasses(
        styles.AmenityCard,
        className,
        description ? styles.withDescription : null,
        imageUrl ? styles.withImage : null
      )}
      {...rest}
    >
      <div className={styles.content}>
        {label && (
          <Label
            className={styles.label}
            data-testid="amenityCardLabel"
            size="s"
          >
            {label}
          </Label>
        )}
        <div className={styles.details}>
          <ItemName className={styles.title} data-testid="amenityCardTitle">
            {name}
          </ItemName>
          <Truncate
            className={styles.description}
            lines={3}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {lock?.access && (
            <DoorCodeTimer
              className={styles.lock}
              label="Unlock"
              accessType={lock.accessType}
              access={lock.access}
              startTime={lock.startTime}
              endTime={lock.endTime}
              onLockError={onUnlockError}
            />
          )}
        </div>
      </div>
      {imageUrl && (
        <Image
          className={styles.thumbnail}
          src={imageUrl}
          alt={`Photo of ${name}`}
          lazy
        />
      )}
    </Card>
  )
}
