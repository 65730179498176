import React from 'react'

import { combineClasses } from '~/util'
import { Title, SubTitle } from '../titles'

import LogoSVG from '~/assets/logomark-is-sad.svg?react'

import './NotFound.scss'

export interface NotFoundProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The page title.
   */
  title: string
  /**
   * The page subtitle.
   */
  subtitle?: string
  /**
   * The name of the thing that could not be found. If the subtitle is not
   * passed, then this will be used in the generated subtitle. This helps ensure
   * our error messaging is consistent throughout. If subtitle IS passed, then
   * this will be ignored.
   */
  lostThing?: string
  /**
   * The phone number for the community.
   */
  phoneNumber?: string
  /**
   * An additional class to apply to the subtitle element.
   */
  classNameSubTitle?: string
}

export default function NotFound({
  title = "Yikes...We're Lost!",
  subtitle,
  lostThing,
  phoneNumber,
  className,
  classNameSubTitle = '',
  ...rest
}: NotFoundProps) {
  if (!subtitle && (lostThing || phoneNumber)) {
    subtitle = `I can't find that${lostThing ? ' ' + lostThing : ''}. `
    subtitle += `Please check the link you followed. `
    // TODO Allow other contact methods
    if (phoneNumber) {
      subtitle += `Or give us a call at ${phoneNumber}.`
    }
  }

  return (
    <div
      data-testid="notFound"
      className={combineClasses('not-found', className)}
      {...rest}
    >
      <Title className="title-large" data-testid="title">
        {title}
      </Title>
      {subtitle && (
        <SubTitle
          className={combineClasses('title-small', classNameSubTitle)}
          data-testid="subtitle"
        >
          {subtitle}
        </SubTitle>
      )}
      <LogoSVG className="logo" />
    </div>
  )
}
