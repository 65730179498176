import { formatDoorCode, removeNulls } from '~/util'
import {
  API_Address,
  API_Amenity,
  API_AmenitySpecialType,
  API_Community,
  API_CommunityConfig,
  API_Feature,
  API_FloorPlan,
  API_FloorPlate,
  API_GuestCard,
  API_LeaseTerm,
  API_LeasingAgent,
  API_Lock,
  API_MediaGallery,
  API_MediaItem,
  API_MediaTag,
  API_Prospect,
  API_Quote,
  API_QuoteCategory,
  API_Tour,
  API_Unit,
  API_VariableRent,
} from './self-tour.service.types'

export const filterCategoryItems = (
  items: API_Quote[],
  type: string,
  unit?: Unit | null
) => {
  if (!unit || !unit.quoteCategoryItemIds) {
    return []
  }
  return items.filter(
    (i) =>
      i.feeType === type &&
      unit.quoteCategoryItemIds &&
      unit.quoteCategoryItemIds.indexOf(i.id) > -1
  )
}

export const findCategoryItemsByFeeType = (
  quoteCategories: API_QuoteCategory[],
  type: string,
  unit?: Unit | null
) => {
  return quoteCategories.flatMap((c) =>
    filterCategoryItems(c.items, type, unit)
  )
}

const scoreItem = (item: API_QuoteCategory) => {
  if (item.name.indexOf('Application') > -1) {
    return 3
  } else if (item.name.indexOf('Pet') > -1) {
    return 2
  } else if (item.name.indexOf('Additional') > -1) {
    return 1
  } else {
    return 0
  }
}

export function sortQuoteCategories(
  a: API_QuoteCategory,
  b: API_QuoteCategory
) {
  return scoreItem(b) - scoreItem(a)
}

export const getFeatureCategoryScore = (category?: string) => {
  switch (category) {
    case 'VIEW':
      return 1
    case 'NARROWING':
      return 2
    default:
      return 3
  }
}

function leaseTerm(term: API_LeaseTerm) {
  return term
}
export type LeaseTerm = ReturnType<typeof leaseTerm>

// TODO Verify that the config keys aren't referenced anywhere else
function config(config: API_CommunityConfig) {
  return {
    ...config,
    address: address(config.address),
  }
}
export type CommunityConfig = ReturnType<typeof config>

function lockDeviceAccess(device: API_Lock, communityName: string) {
  return {
    id: device.lockDeviceId,
    accessType: device.accessType,
    access:
      device.accessType === 'PIN'
        ? formatDoorCode(device.access, communityName)
        : device.access,
    startTime: device.startTime,
    endTime: device.endTime,
  }
}
export type Lock = ReturnType<typeof lockDeviceAccess>

function address(address: API_Address) {
  return {
    /**
     * All `addressLines` concatenated into a single comma separated string that
     * can be rendered directly to the user.
     */
    street: address?.addressLines?.join(', '),
    ...address,
  }
}
export type Address = ReturnType<typeof address>

function feature(feature: API_Feature) {
  return feature
}
export type Feature = ReturnType<typeof feature>

// PEOPLE
// ======

function prospect(prospect: API_Prospect) {
  return prospect
}
export type Prospect = ReturnType<typeof prospect>

function leasingAgent(agent: API_LeasingAgent) {
  return agent
}
export type LeasingAgent = ReturnType<typeof leasingAgent>

// MEDIA
// =====

function mediaTag(tag: API_MediaTag) {
  return tag
}
export type MediaTag = ReturnType<typeof mediaTag>

function mediaItem(item: API_MediaItem, mediaTags: API_MediaTag[] = []) {
  return {
    ...item,
    /**
     * The media tag objects associated with this media item
     */
    mediaTags: (item?.mediaTagIds
      ?.map((id) => mediaTags?.find((t) => t.id === id))
      .filter((v) => !!v) || []) as API_MediaTag[],
  }
}
export type MediaItem = ReturnType<typeof mediaItem>

function mediaGallery(
  gallery: API_MediaGallery,
  mediaTags: API_MediaTag[] = []
) {
  return {
    ...gallery,
    /**
     * The media items in this media galler.
     */
    mediaItems: gallery?.mediaItems?.map((item) => mediaItem(item, mediaTags)),
  }
}
export type MediaGallery = ReturnType<typeof mediaGallery>

// FLOOR PLATES
// ============

function floorPlate(plate: API_FloorPlate) {
  return {
    ...plate,
    floor: plate.floor ?? 1,
  }
}
export type FloorPlate = ReturnType<typeof floorPlate>

// QUOTES
// ======

// TODO This doesn't seem to be in use anymore
function quoteItem(item: any) {
  return item
}

function quoteCategory(category: API_QuoteCategory) {
  return category
}
export type QuoteCategory = ReturnType<typeof quoteCategory>

function quote(
  quote: API_Quote,
  /**
   * The full list of community lease terms.
   */
  leaseTerms: API_LeaseTerm[] = [],
  /**
   * The full list of community units with relationships
   *   already merged.
   */
  units: Unit[] = [],
  /**
   * The full list of community quote categories.
   */
  quoteCategories: API_QuoteCategory[] = [],
  /**
   * The generic community quote highlights text.
   */
  highlights?: string,
  /**
   * The generic community quote footnotes text.
   */
  footnotes?: string
) {
  const unit = quote.unitId ? units.find((u) => u.id === quote.unitId) : null

  const toEOD = (date?: string) => date && date + 'T23:59:59'
  const toBOD = (date?: string) => date && date + 'T00:00:00'

  return {
    ...quote,

    // Convert the following dates to timestamps at the end of the
    // day on the given date.
    expirationDate: toEOD(quote.expirationDate),
    moveOutDate: toEOD(quote.moveOutDate),

    // Convert the following dates to timestamps at the beginning
    // of the dan on the given date.
    leaseStartDate: toBOD(quote.leaseStartDate),
    moveInDate: toBOD(quote.moveInDate),

    // Merge in the lease term object.
    leaseTerm: quote.leaseTermId
      ? leaseTerms.find((t) => t.id === quote.leaseTermId)
      : null,

    // Merge in the unit data.
    unit,

    // Organize the charges to show on the quote details page
    // to match what the UI uses.
    charges: {
      // Fees required at move in.
      required: findCategoryItemsByFeeType(quoteCategories, 'REQUIRED', unit),
      // Fees required every month.
      monthly: findCategoryItemsByFeeType(quoteCategories, 'MONTHLY', unit),
      // Optional fees like parking or pet deposit.
      additional: quoteCategories
        .map((category) => ({
          ...category,
          items: filterCategoryItems(category.items, 'NON_REQUIRED', unit),
        }))
        // Remove any quote categories that do not have NON_REQUIRED items
        .filter((c) => c.items.length > 0)
        .sort(sortQuoteCategories),
    },

    // Include the community quote highlights and footnotes text.
    footnotes,
    highlights,
  }
}
export type Quote = ReturnType<typeof quote>

// AMENITIES
// =========

function amenitySpecialType(type: API_AmenitySpecialType) {
  return type
}
export type AmenitySpecialType = ReturnType<typeof amenitySpecialType>

function amenity(
  amenity: API_Amenity,
  /**
   * The amenitySpecialTypes list
   */
  specialTypes?: API_AmenitySpecialType[],
  /**
   * The full list of community floor plates.
   */
  floorPlates?: API_FloorPlate[],
  /**
   * The list of all community lock devices.
   */
  lockDeviceAccesses: Lock[] = []
) {
  const floorPlate = amenity.floorPlateId
    ? floorPlates?.find((f) => f.id === amenity.floorPlateId)
    : undefined
  const specialType = amenity.specialTypeId
    ? specialTypes?.find((t) => t.id === amenity.specialTypeId)
    : undefined

  return {
    ...amenity,
    // Rename these to "Main Entrance" per Ian's request. Ideally, we would
    // create a new special type or rename the existing one in the API. They
    // don't want to create a new special type because it would require updating
    // all communities. Not sure if the existing one can be renamed.
    name:
      specialType?.name === 'Leasing Office' ? 'Main Entrance' : amenity.name,
    // Merge in the floor plate data.
    floorPlate,
    floor: floorPlate?.floor ?? 1,
    specialType,
    // Merge in the lock info.
    lockDevice:
      lockDeviceAccesses.find((d) => d.id === amenity.lockDeviceId) || null,
  }
}
export type Amenity = ReturnType<typeof amenity>

function floorPlan(plan: API_FloorPlan, mediaTags: API_MediaTag[] = []) {
  return {
    ...plan,
    mediaItems: plan.mediaItems?.map((i) => mediaItem(i, mediaTags)),
  }
}
export type FloorPlan = ReturnType<typeof floorPlan>

// UNITS
// =====

function unit(
  unit: API_Unit,
  /**
   * The list of all community features
   */
  features: API_Feature[] = [],
  /**
   * The list of all community floor plates.
   */
  floorPlates: API_FloorPlate[] = [],
  /**
   *
   * The list of all community floor plans.
   */
  floorPlans: API_FloorPlan[] = [],
  /**
   * The list of all community lock devices.
   */
  lockDeviceAccesses: Lock[] = [],
  /**
   * The list of all community lease terms.
   */
  leaseTerms: API_LeaseTerm[] = [],
  /**
   * The variable rent data for the
   *   current unit. If the community uses variable rent pricing but this unit does
   *   not have any pricing specified, then this will be an empty array.
   *   If the community does not use variable rent pricing, then this will
   *   be false.
   */
  variableRentData?: API_VariableRent[],
  mediaTags: API_MediaTag[] = []
) {
  // Pricing on a unit can be undefined
  let price: number | undefined
  let leaseTerm = leaseTerms.find((t) => t.length === unit.leaseTermLength)
  let leaseTermLength = unit.leaseTermLength

  let fp: any = floorPlans.find((f) => f.id === unit.floorPlanId)
  if (fp) fp = floorPlan(fp, mediaTags)

  // If the community does not use variable rent pricing,
  // or the unit is tax credit eligible,
  // use the rent as the price.
  // if (!variableRentData || unit.isTaxCreditEligible || !leaseTerms || leaseTerms.length < 1) {
  if (!variableRentData || unit.isTaxCreditEligible) {
    price = unit.rent
  }
  // If the community does use variable rent pricing and
  // we can find pricing for this unit,
  // set the price as the lowest variable rent for the unit.
  else if (variableRentData.length > 0) {
    const lowestRent = variableRentData.reduce((acc, curr) =>
      curr.rent < acc.rent ? curr : acc
    )
    price = lowestRent.rent

    // If we can find the lease term, add that to the unit data.
    leaseTerm = leaseTerms.find((t) => t.id === lowestRent.leaseTermId)
    leaseTermLength = leaseTerm?.length
  }

  const out = {
    id: unit.id,
    buildingNumber: unit.buildingNumber,
    unitNumber: unit.unitNumber,
    engrainUnitId: unit.engrainUnitId,
    description: unit.description,
    isAvailableForTour: unit.isAvailableForTour,
    availableTime: unit.availableTime,
    isTaxCreditEligible: unit.isTaxCreditEligible,
    featureIds: unit.featureIds,
    mediaItems: unit.mediaItems?.map((i) => mediaItem(i, mediaTags)),
    promotion: unit.promotion,
    floorPlateId: unit.floorPlateId,
    floorPlateImagePosition: unit.floorPlateImagePosition,
    floorPlanId: unit.floorPlanId,
    floor: unit.floor ?? 1,
    quoteCategoryItemIds: unit.quoteCategoryItemIds,
    tourUrl: unit.tourUrl,
    isModel: unit.isModel,

    // Note that we add the `price` to the Unit but not `rent`.
    // This should help avoid confusion on which to use.
    price,
    leaseTermLength,
    leaseTerm,
    floorPlan: fp as FloorPlan,

    // Merge in the features.
    features: !!unit.featureIds
      ? unit.featureIds
          .map((id) => features.find((f) => f.id === id))
          // Filter out any feature ids that didn't have a matching feature.
          .filter(removeNulls)
          .sort((a, b) => {
            return (
              getFeatureCategoryScore(a.category) -
              getFeatureCategoryScore(b.category)
            )
          })
      : [],

    // Merge in floor plate data.
    floorPlate:
      unit.floorPlateId != null
        ? floorPlates.find((f) => f.id === unit.floorPlateId)
        : undefined,

    // Merge in the lock device.
    // This assumes only one lock device access per unit.
    lockDeviceId: unit.lockDeviceId,
    lockDevice:
      lockDeviceAccesses.find((d) => d.id === unit.lockDeviceId) || null,
  }

  return out
}
export type Unit = ReturnType<typeof unit>

// TOUR
// ====

function community(
  community: API_Community,
  /**
   * The variable rent data
   *   for the current community.
   */
  variableRentData?: API_VariableRent[],
  lockDeviceAccesses: Lock[] = []
) {
  const mediaTags = community.mediaTags?.map(mediaTag)

  const features = community.features?.map(feature)
  const floorPlates = community.floorPlates?.map(floorPlate)
  const floorPlans = community.floorPlans?.map((f) => floorPlan(f, mediaTags))
  const leaseTerms = community.leaseTerms?.map(leaseTerm)
  const amenitySpecialTypes =
    community.amenitySpecialTypes?.map(amenitySpecialType)

  // Units with merged data.
  const units = community.units?.map((u) =>
    unit(
      u,
      features,
      floorPlates,
      floorPlans,
      lockDeviceAccesses,
      leaseTerms,
      variableRentData && variableRentData.filter((r) => r.unitId === u.id),
      mediaTags
    )
  )

  // All amenities
  const amenities = community.amenities?.map((a) =>
    amenity(a, amenitySpecialTypes, floorPlates, lockDeviceAccesses)
  )

  // Amenities that don't have special types.
  const communityAmenities = amenities?.filter((a) => !a.specialTypeId)
  // Leasing office amenities.
  const entrances = amenities?.filter(
    // Ian requested that "Leasing Office" special type be displayed as
    // "Main Entrance". However, the API will continue to call this "Leasing Office".
    (a) => a.specialType?.name === 'Leasing Office'
  )

  return {
    ...community,

    features,
    floorPlates,
    floorPlans,
    leaseTerms,
    amenitySpecialTypes,
    units,
    amenities,
    communityAmenities,
    entrances,

    mediaTags,
    mediaGalleries: community.mediaGalleries?.map((g) =>
      mediaGallery(g, mediaTags)
    ),

    //config: fromSelfTour.config(community.config),
    quoteCategories: community.quoteCategories?.map(quoteCategory),
  }
}
export type Community = ReturnType<typeof community>

function guestCard(
  guestCard: API_GuestCard,
  /**
   * The list of all units on the community
   *   with relationships already merged in.
   */
  units: Unit[] = [],
  /**
   * The list of all community lease terms.
   */
  leaseTerms: API_LeaseTerm[] = [],
  /**
   * The list of all community quote categories.
   */
  quoteCategories: API_QuoteCategory[] = [],
  /**
   * The pre-converted lock device info.
   */
  lockDeviceAccesses: Lock[] = [],
  quoteHighlights?: string,
  quoteFootnotes?: string
) {
  const out = {
    ...guestCard,

    // Merge in the tour units.
    tourableUnits:
      guestCard.tourableUnitIds
        ?.map((id) => units.find((u) => u.id === id))
        // Filter out any units that couldn't be found
        // or that can't be toured at the moment.
        .filter(removeNulls)
        .filter((u) => u.isAvailableForTour) || [],

    // Merge in the shown units.
    shownUnits:
      guestCard.shownUnitIds
        ?.map((id) => units.find((u) => u.id === id))
        .filter(removeNulls) || [],

    // Merge in favorites
    interestedUnits:
      guestCard.interestedUnitIds
        ?.map((id) => units.find((u) => u.id === id))
        .filter(removeNulls) || [],

    // Merge in quotes with details
    quotes:
      guestCard.quotes?.map((q) =>
        quote(
          q,
          leaseTerms,
          units,
          quoteCategories,
          quoteHighlights,
          quoteFootnotes
        )
      ) || [],

    // Add the lock device info
    lockDeviceAccesses,
  }
  return out
}

export type GuestCard = ReturnType<typeof guestCard>

function tour(tour: API_Tour, variableRentData?: API_VariableRent[]) {
  // Pre-convert the lock devices so we can merge those into
  // the unit objects in the community object.
  const lockDeviceAccesses = (tour.guestCard.lockDeviceAccesses || []).map(
    (d) => lockDeviceAccess(d, tour.community.name)
  )

  const c = community(tour.community, variableRentData, lockDeviceAccesses)
  const conf = config(tour.config)
  const gc = guestCard(
    tour.guestCard,
    c.units,
    c.leaseTerms,
    c.quoteCategories,
    lockDeviceAccesses,
    tour.config.quoteHighlights,
    tour.config.quoteFootNotes
  )

  return {
    config: conf,
    community: c,
    guestCard: gc,
  }
}
export type Tour = ReturnType<typeof tour>

// Unfortunately, this is the easiest way I can think of to export these
// functions under a namespace and the types without a namespace.
export const fromSelfTour = {
  leaseTerm,
  config,
  lockDeviceAccess,
  address,
  feature,
  prospect,
  leasingAgent,
  mediaTag,
  mediaItem,
  mediaGallery,
  floorPlate,
  quoteItem,
  quoteCategory,
  quote,
  amenitySpecialType,
  amenity,
  floorPlan,
  unit,
  community,
  guestCard,
  tour,
}
