import { combineClasses } from '@thesoulfresh/utils'
import { Nilable } from 'tsdef'

import { Lock } from '~/service'
import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { Card, CardProps } from '../Card'

import StarIcon from '~/assets/icons/star.svg?react'
import styles from './EntranceCard.module.scss'
import { DoorCodeTimer } from '~/components'

export interface EntranceCardProps extends Omit<CardProps, 'onLockError'> {
  /**
   * The name of this entrance. Defaults to 'Main Entrance' if not
   * specified.
   */
  name?: string
  /**
   * The lock device associated with this entrance.
   */
  lock: Nilable<Lock>
  /**
   * Callback if a remote lock fails to open.
   */
  onLockError: (label: string, e: unknown) => void
}

/**
 * `<Entrance>` shows the summary of an entrance such as the lock codes used to
 * open that entrance.
 */
export const EntranceCard = ({
  name = 'Main Entrance',
  lock,
  onLockError,
  transparent,
  className,
  ...rest
}: EntranceCardProps) => {
  return (
    <Card
      data-testid="EntranceCard"
      contentClassName={combineClasses(styles.EntranceCard, className)}
      transparent={transparent}
      {...rest}
    >
      <Label
        className={styles.label}
        size="s"
        children={<StarIcon />}
        display="error"
      />
      <ItemName className={styles.title} data-testid="EntranceCard.title">
        {name}
      </ItemName>
      {!!lock && (
        <DoorCodeTimer
          data-testid="EntranceCard.lock"
          className={styles.DoorCode}
          label="Unlock"
          accessType={lock.accessType}
          access={lock.access}
          startTime={lock.startTime}
          endTime={lock.endTime}
          onLockError={onLockError}
        />
      )}
    </Card>
  )
}
