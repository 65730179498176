import React from 'react'
import PropTypes from 'prop-types'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'
import { PageTakeover } from '../page-takeover/PageTakeover'
import { ZoomInAction, ZoomOutAction } from '../buttons'
import {
  combineClasses,
  useDropdownAria,
  useFocusTrap,
  useKeyWhenActive,
} from '~/util'
import { Image } from '../image'
import styles from './ExpandableHeaderImage.module.scss'

export function ExpandableHeaderImageContent({
  contentContainerClassName,
  imageClassName,
  src,
  alt,
  expanded = false,
  minHeight,
  preExpanded,
  onToggleExpand,
  onLoad,
  elementRef,
  children,
  ...rest
}) {
  const aria = useDropdownAria(expanded)
  // When the map is fullscreen, it should act as a focus trap
  // so that elements behind the map are not reachable with
  // the keyboard.
  const [lastFocusableRef] = useFocusTrap(expanded)

  useKeyWhenActive('Escape', onToggleExpand, expanded)
  return (
    <div
      className={combineClasses(
        styles.ExpandableMapContent,
        contentContainerClassName
      )}
      ref={elementRef}
      style={{
        minHeight: minHeight,
      }}
      {...rest}
    >
      <Image
        data-testid="floorPlanImage"
        className={imageClassName}
        src={src}
        alt={alt}
        onLoad={onLoad}
        display="block"
        fit="contain"
        width="100%"
        height="100%"
        responsive
      />
      {preExpanded && (
        <ZoomOutAction
          data-testid="zoomOutButton"
          className={styles.zoomButton}
          onClick={onToggleExpand}
          button
          size="m"
          transparent
          action="Zoom"
          label="Zoom Out"
          {...aria.triggerProps}
          ref={lastFocusableRef}
        />
      )}
      {!preExpanded && (
        <ZoomInAction
          data-testid="zoomInButton"
          className={styles.zoomButton}
          onClick={onToggleExpand}
          button
          size="m"
          transparent
          action="Zoom"
          label="Zoom In"
          {...aria.triggerProps}
          ref={lastFocusableRef}
        />
      )}
    </div>
  )
}

/**
 * `<ExpandableHeaderImage>`
 *
 * @param {object} props
 * @param {string} [props.contentContainerClassName]
 * @param {string} [props.imageClassName]
 * @param {string} [props.src]
 * @param {string} [props.alt]
 * @param {boolean} [props.allowBodyScroll]
 * @param {boolean} [props.preExpanded]
 * @param {function} [props.onLoad]
 * @param {function} [props.onMaximize]
 * @param {function} [props.onMinimize]
 * @param {string} [props.onToggleExpand]
 */
export function ExpandableHeaderImage({
  contentContainerClassName,
  imageClassName,
  src,
  alt,
  allowBodyScroll,
  onLoad,
  onMaximize: onMaximizeProp,
  onMinimize: onMinimizeProp,
  ...rest
}) {
  const [expanded, setExpanded] = React.useState(false)

  const onMinimize = (e) => {
    onMinimizeProp?.(e)
    setExpanded(false)
  }
  const onMaximize = (e) => {
    onMaximizeProp?.(e)
    setExpanded(true)
  }

  // Prevent page scrolling while expanded.
  useLockBodyScroll(expanded && !allowBodyScroll)
  return (
    <PageTakeover
      className={styles.PageTakeover}
      onMaximize={onMaximize}
      onMinimize={onMinimize}
    >
      <ExpandableHeaderImageContent
        contentContainerClassName={contentContainerClassName}
        imageClassName={imageClassName}
        src={src}
        alt={alt}
        onLoad={onLoad}
        {...rest}
      />
    </PageTakeover>
  )
}

ExpandableHeaderImage.propTypes = {
  contentContainerClassNames: PropTypes.string,
  imageClassNames: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  allowBodyScroll: PropTypes.bool,
  preExpanded: PropTypes.bool,
  onLoad: PropTypes.bool,
  onToggleExpand: PropTypes.string,
}
