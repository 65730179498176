import React from 'react'

import { combineClasses, formatUnitName } from '~/util'

import { FavoritesAction } from '../../buttons'
import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { UnitPrice } from '../../unit-price'
import { UnitQuickFacts } from '../../unit-quick-facts'
import { DoorCodeTimer } from '../../door-code'

import { Card } from '../Card'

import styles from './UnitCard.module.scss'
import { ExpandableHeaderImage } from '~/components'
import { Nilable } from 'tsdef'
import { Lock, Unit } from '~/service'

interface DetailsProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onLockError'> {
  title: string
  price: Nilable<number>
  beds: Nilable<number>
  baths: Nilable<number>
  footage: Nilable<number>
  lock: Nilable<Lock>
  onLockError: (name: string, error: unknown) => void
  isFavorite?: boolean
  onToggleFavorite?: (e: React.MouseEvent) => void
}

export function Details({
  title,
  price,
  beds,
  baths,
  footage,
  lock,
  onLockError,
  isFavorite,
  onToggleFavorite,
  ...rest
}: DetailsProps) {
  const hasDetails = beds || baths || footage || lock?.access

  return (
    <div
      className={combineClasses(
        styles.details,
        lock?.access ? styles.hasDoorCode : styles.noDoorCode
      )}
      {...rest}
    >
      <div className={styles.headerRow}>
        {title && (
          <ItemName className={styles.itemTitle} data-testid="unitTitle">
            {title}
          </ItemName>
        )}
        {price && (
          <UnitPrice
            className={styles.itemPrice}
            data-test="price"
            price={price}
          />
        )}
      </div>

      {hasDetails && (
        <div className={styles.details}>
          <UnitQuickFacts
            className={styles.quickFacts}
            bedroomCount={beds}
            bathroomCount={baths}
            squareFootageCount={footage}
          />

          <div className={styles.doorCodeAndActions}>
            {lock?.access && (
              <DoorCodeTimer
                className={styles.DoorCode}
                label="Unlock"
                accessType={lock.accessType}
                access={lock.access}
                startTime={lock.startTime}
                endTime={lock.endTime}
                onLockError={onLockError}
              />
            )}
            {onToggleFavorite && (
              <FavoritesAction
                data-testid="FavoritesAction"
                className={styles.FavoritesAction}
                isToggled={isFavorite}
                onClick={onToggleFavorite}
                button
              >
                {isFavorite ? 'Remove' : 'Add'} Favorite
              </FavoritesAction>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

interface UnitCardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onLockError'> {
  /**
   * Unit object.
   */
  unit: Unit
  /**
   * Usually the unit number and is displayed in the blue unitNumber Card.
   */
  label: string
  /**
   * string to be passed to set the title of Apartment Destination
   * if not passed unit.number and floor.plate name will be used.
   */
  title?: string
  /**
   * determines if unit prices should be displayed.
   */
  showUnitPrices?: boolean
  /**
   * dermines if the Unit floorPlate should be displayed.
   */
  showFloorPlanImage?: boolean
  /**
   * class for customizing the plage image
   */
  floorPlateImageClassName?: string
  /**
   * If this is specified, then the favorites button
   * will be shown, allowing the user to add/remove
   * the unit from their favorites.
   */
  onToggleFavorite?: (...args: unknown[]) => unknown
  /**
   * Whether the unit is currently in the users favorites.
   */
  isFavorite?: boolean
  /**
   * Whether display price of a model unit.
   */
  isShowPriceOnModel?: boolean
  /**
   * called when floorPlateImage is loaded.
   */
  onLoad?: (...args: unknown[]) => unknown
  onLockError: (name: string, error: unknown) => void
  onFloorPlanMaximized?: (e: React.MouseEvent) => void
  onFloorPlanMinimized?: (e: React.MouseEvent) => void
}

export function UnitCard({
  unit,
  label,
  title = formatUnitName(unit, false),
  showUnitPrices,
  onLoad,
  showFloorPlanImage = false,
  className,
  floorPlateImageClassName,
  isFavorite,
  onToggleFavorite,
  isShowPriceOnModel,
  onLockError,
  onFloorPlanMaximized,
  onFloorPlanMinimized,
  ...rest
}: UnitCardProps) {
  const cardRef = React.useRef(null)

  return (
    <Card
      ref={cardRef}
      data-testid="UnitCard"
      className={combineClasses(
        styles.UnitCard,
        !showFloorPlanImage ? styles.row : styles.column,
        !unit.floorPlan?.imageUrl ? styles.noImage : '',
        className
      )}
      {...rest}
    >
      <div className={styles.content}>
        {label && (
          <div className={styles.unitNumberContainer}>
            <Label
              data-testid="label"
              children={label}
              size="m"
              display="primary"
            />
          </div>
        )}
        {showFloorPlanImage && unit.floorPlan?.imageUrl && (
          <div className={styles.floorPlanImageContainer}>
            <ExpandableHeaderImage
              src={unit.floorPlan.imageUrl}
              alt={`${unit.floorPlan?.name} Floor Plan`}
              onLoad={onLoad}
              onMaximize={onFloorPlanMaximized}
              onMinimize={onFloorPlanMinimized}
            />
          </div>
        )}
        <Details
          data-testid="unit"
          title={title}
          price={
            showUnitPrices &&
            (isShowPriceOnModel || (!isShowPriceOnModel && !unit.isModel))
              ? unit.price
              : null
          }
          beds={unit.floorPlan?.bedrooms}
          baths={unit.floorPlan?.bathrooms}
          footage={unit.floorPlan?.squareFootage}
          lock={unit.lockDevice}
          onLockError={onLockError}
          isFavorite={isFavorite}
          onToggleFavorite={onToggleFavorite}
        />
      </div>
    </Card>
  )
}
