import React from 'react'

import { combineClasses } from '~/util'
import { Action } from '../buttons'

import styles from './Card.module.scss'
import { Nilable } from 'tsdef'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Using this flag will remove
   *   the default background color and borders.
   */
  transparent?: boolean
  /**
   * Passing a `to` prop will make the card a link.
   */
  href?: Nilable<string>
  blank?: boolean
  contentClassName?: string
}

/**
 * Use this generic card container as the base for custom cards.
 * This is a good place to put any functionality/styling that should
 * be the same across all cards.
 */
export let Card = React.memo(
  React.forwardRef<HTMLDivElement, CardProps>(
    (
      {
        className,
        contentClassName,
        transparent,
        children,
        href,
        blank,
        'aria-label': ariaLabel,
        ...rest
      },
      cardRef
    ) => {
      return (
        <div
          ref={cardRef}
          data-testid="Card"
          className={combineClasses(
            styles.Card,
            className,
            transparent ? styles.transparent : null
          )}
          {...rest}
        >
          {href && (
            // Absolutely position the link behind the content so we can add
            // buttons inside the card.
            <Action
              data-testid="cardLink"
              className={styles.link}
              href={href}
              blank={blank}
              aria-label={ariaLabel}
              unstyled
            />
          )}
          <div
            data-testid="Card.content"
            className={combineClasses(styles.content, contentClassName)}
          >
            {children}
          </div>
        </div>
      )
    }
  )
)
