/// <reference types="vite-plugin-svgr/client" />
import React from 'react'

import { combineClasses, formatDateWithTime, removeNulls } from '~/util'

import { ItemName, SubTitle } from '../titles'
import { AddToCalendar } from '../add-to-calendar'

import styles from './AppointmentWelcomeMessage.module.scss'
import { Address, DateLike } from '~/service'
import { Action } from '../buttons'
import { googleMapsLink } from '~/util'
import { Nilable } from 'tsdef'

export interface AppointmentWelcomeMessageProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  /**
   * The primary line of the welcome message.
   */
  title?: React.ReactNode
  /**
   * The subtitle of the welcome message. Defaults to "Your tour is scheduled
   * for X". Pass `null` to hide the subtitle.
   */
  subtitle?: Nilable<React.ReactNode>
  communityName?: Nilable<string>
  primaryProspect?: Nilable<string>
  /**
   * The community address.
   */
  address: Nilable<Address>
  /**
   * The url that the "Directions" link should point to. If not passed, a Google
   * Maps URL will be generated from the address.
   */
  directionsURL?: string
  startDatetime: Nilable<DateLike>
  /**
   * The title of the calendar event, should the user choose to add the tour
   * date to their calendar.
   */
  calendarEventTitle?: string
  /**
   * The description of the calendar event should the user choose to add their
   * tour to their calendar.
   */
  calendarEventDescription?: string
}

/**
 * `<AppointmentWelcomeMessage>` shows a welcome message on the tour page with
 * the user's tour date.
 */
export function AppointmentWelcomeMessage({
  className,
  communityName,
  primaryProspect,
  address,
  startDatetime,
  title = primaryProspect
    ? `Hi ${primaryProspect}${startDatetime ? '!' : ','}`
    : 'Get Excited For Your Tour!',
  subtitle = startDatetime ? "We can't wait to see you!" : undefined,
  calendarEventTitle = `Your ${communityName} Tour`,
  // TODO The picture ID requirement needs to be conditional now
  calendarEventDescription = `We are excited for your tour! Please remember to bring a valid picture ID. Thank you!`,
  directionsURL = address ? googleMapsLink(address) : undefined,
  children,
  ...rest
}: AppointmentWelcomeMessageProps) {
  const eventLocation = [
    ...(address?.addressLines || []),
    address?.city,
    address?.state,
    address?.postalCode,
  ]
    .filter((a) => !a)
    .join(', ')
  const tempDate = startDatetime ? new Date(startDatetime) : null
  const event = startDatetime
    ? {
        title: calendarEventTitle,
        description: calendarEventDescription,
        location: eventLocation,
        startDatetime: startDatetime,
        endDatetime: tempDate
          ? new Date(tempDate.setHours(tempDate.getHours() + 1))
          : undefined,
      }
    : null

  return (
    <div
      data-testid="AppointmentWelcomeMessage"
      className={combineClasses(
        styles.AppointmentWelcomeMessage,
        !!children && styles.withChildren,
        className
      )}
      {...rest}
    >
      <div className={styles.headerSection}>
        <SubTitle
          data-testid="appointmentSectionHeaderText"
          className={combineClasses(styles.headerText, styles.blueTitleText)}
          as="h1"
        >
          {title}
        </SubTitle>
        {subtitle && (
          <ItemName
            as="h2"
            className={styles.headerText}
            data-testid="appointmentSectionSubtitle"
          >
            {subtitle}
          </ItemName>
        )}
        {children}
      </div>
      <section className={styles.addToCalendarSection}>
        {communityName && (
          <div data-testid="communityName" className={styles.communityName}>
            at {communityName}
          </div>
        )}
        {event?.startDatetime && (
          <div
            data-testid="startTimeDateString"
            className={styles.addToCalendarSectionDate}
          >
            {formatDateWithTime(event?.startDatetime)}
          </div>
        )}
        {event && (
          <div>
            <AddToCalendar
              className={styles.AddToCalendar}
              data-testid="AddToCalendar"
              title={event.title}
              description={event.description}
              location={event.location}
              startDatetime={event.startDatetime}
              endDatetime={event.endDatetime}
            />
          </div>
        )}
        {address && (
          <Action
            data-testid="addressLink"
            href={directionsURL}
            blank
            className={styles.directionsLink}
          >
            {address.addressLines?.length > 0 && (
              <>
                {address.addressLines?.join(', ') + ' '}
                <br />
              </>
            )}
            {[address.city, address.state].filter(removeNulls).join(', ')}{' '}
            {address.postalCode}
          </Action>
        )}
      </section>
    </div>
  )
}
