import PropTypes from 'prop-types'

export const DESTINATION_TYPES = {
  UNIT: 'UNIT',
  AMENITY: 'AMENITY',
  ENTRANCE: 'ENTRANCE',
}

export const MEDIA_TYPES = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
}

export const IdPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
])

/**
 * @typedef ImagePositionPropType
 * @property {number} x
 * @property {number} y
 */
export const ImagePositionPropType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
})

/**
 * @typedef FloorPlanPropType
 * @property {number} id
 * @property {string} [name]
 * @property {string} [imageUrl]
 * @property {number} [squareFootage]
 * @property {number} bedrooms
 * @property {number} bathrooms
 */
export const FloorPlanPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  squareFootage: PropTypes.number,
  bedrooms: PropTypes.number,
  bathrooms: PropTypes.number,
})

/**
 * A single geo location point.
 */
export const GeoMarkerPropType = PropTypes.shape({
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
})

/**
 * A rectangle defining the bounds of a geo location area.
 */
export const GeoRectPropType = PropTypes.shape({
  tl: GeoMarkerPropType.isRequired,
  tr: GeoMarkerPropType.isRequired,
  br: GeoMarkerPropType.isRequired,
  bl: GeoMarkerPropType.isRequired,
})

/**
 * @typedef FloorPlatePropType
 * @property {number} id
 * @property {string} name
 * @property {string} [description]
 * @property {number} [floor]
 * @property {string} imageUrl
 */
export const FloorPlatePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  floor: PropTypes.number,
  imageUrl: PropTypes.string.isRequired,
})

/**
 * @typedef UnitPropType
 * @property {number} id
 * @property {string} [buildingNumber]
 * @property {string} unitNumber
 * @property {string} [description]
 * @property {number} [floorPlateId]
 * @property {ImagePositionPropType} [floorPlateImagePosition]
 * @property {number} [floorPlanId]
 * @property {FloorPlanPropType} [floorPlan]
 * @property {number} [price]
 * @property {number} [leaseTermLength]
 * @property {boolean} [isTaxCreditEligible]
 * @property {string} [promotion]
 */
export const UnitPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  buildingNumber: PropTypes.string,
  unitNumber: PropTypes.string.isRequired,
  engrainUnitId: PropTypes.string,
  description: PropTypes.string,
  floorPlateId: PropTypes.number,
  floorPlate: FloorPlatePropType,
  floorPlateImagePosition: ImagePositionPropType,
  floorPlanId: PropTypes.number,
  floorPlan: FloorPlanPropType,
  price: PropTypes.number,
  leaseTermLength: PropTypes.number,
  isTaxCreditEligible: PropTypes.bool,
  promotion: PropTypes.string,
})

/**
 * @typedef AmenityPropType
 * @property {number} id
 * @property {string} name
 * @property {string} [imageUrl]
 * @property {string} [description]
 * @property {number} [floorPlateId]
 * @property {ImagePositionPropType} [floorPlateImagePosition]
 */
export const AmenityPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  description: PropTypes.string,
  flootPlateId: PropTypes.number,
  floorPlateImagePosition: ImagePositionPropType,
})

export const LeasingAgentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
})

export const QuotePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  deposit: PropTypes.number.isRequired,
  rent: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  leaseStartDate: PropTypes.string.isRequired,
  moveInDate: PropTypes.string.isRequired,
  moveOutDate: PropTypes.string.isRequired,
  reservationUrl: PropTypes.string.isRequired,
  thirdPartyReferenceId: PropTypes.string.isRequired,
  leaseTermId: PropTypes.number,
  unitId: PropTypes.number.isRequired,
  notes: PropTypes.string,
  leasingAgent: LeasingAgentPropType,
})

export const VideoTourPropType = {
  /**
   * The url of the video for this video tour.
   */
  url: PropTypes.string.isRequired,
  /**
   * The shortened version of the URL for user consumption.
   */
  shortenedUrl: PropTypes.string,
  /**
   * The display name of this video tour.
   */
  name: PropTypes.string.isRequired,
  /**
   * The description of this video tour.
   */
  description: PropTypes.string,
}

export const MediaItemPropTypeDef = {
  id: IdPropType,
  /**
   * A human readable short name to for this media.
   */
  name: PropTypes.string,
  /**
   * A more detailed description of the media.
   */
  description: PropTypes.string,
  /**
   * The full URL to the media.
   */
  url: PropTypes.string.isRequired,
  /**
   * A shortened version of the URL for display purposes.
   */
  shortendUrl: PropTypes.string,
  /**
   * An thumbnail image for the media if it is a non-image media.
   */
  previewUrl: PropTypes.string,
  /**
   * A third party key for accessing the media should that be necessary.
   */
  thirdPartyKey: PropTypes.string,
  /**
   * The type ENUM for this media.
   */
  type: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
}

export const MediaItemPropType = PropTypes.shape(MediaItemPropTypeDef)

export const MediaGalleryPropTypeDef = {
  id: IdPropType,
  name: PropTypes.string,
  mediaItems: PropTypes.arrayOf(MediaItemPropType),
}

export const MediaGalleryPropType = PropTypes.shape(MediaGalleryPropTypeDef)

/**
 * Defines the position of an item with a bounding area
 * as a percentage x/y within those bounds.
 */
export const PositionPropTypeDef = {
  /**
   * The x location of the item as a number between 0-1.
   */
  x: PropTypes.number.isRequired,
  /**
   * The y location of the item as a number between 0-1.
   */
  y: PropTypes.number.isRequired,
}
export const PositionPropType = PropTypes.shape(PositionPropTypeDef)

export const MarkerPropTypeDef = {
  /**
   * The x location of the point on the map (in pixels).
   */
  x: PropTypes.number,
  /**
   * The y location of the point on the map (in pixels).
   */
  y: PropTypes.number,
  /**
   * The floor that this marker is on.
   */
  floor: IdPropType,
  /**
   * The building this marker is in.
   */
  building: IdPropType,
  /**
   * The index of this point in the flat list of tour steps.
   */
  index: PropTypes.number,
  /**
   * Space separated list of classes to apply to the pin representing this point.
   */
  classes: PropTypes.string,
  /**
   * The icon to display inside of this pin.
   */
  icon: PropTypes.string,
  /**
   * The display title for this marker.
   */
  title: PropTypes.string,
  /**
   * The type of item this map point represents.
   */
  type: PropTypes.oneOf(Object.keys(DESTINATION_TYPES)).isRequired,
  /**
   * The item that this point represents.
   */
  item: PropTypes.oneOfType([AmenityPropType, UnitPropType]).isRequired,
}
export const MarkerPropType = PropTypes.shape(MarkerPropTypeDef)

/**
 * A structure that describes the markers, units and amenities for
 * a single floor plate in a multi floor map.
 */
export const MapFloorPlatePropTypeDef = {
  /**
   * The floor plate represented by this map.
   */
  floorPlate: FloorPlatePropType,
  /**
   * The full list of all markers (units and amenities) to display on this map.
   */
  markers: PropTypes.arrayOf(MarkerPropType).isRequired,
  /**
   * The list of the units placed on this map as markers.
   */
  units: PropTypes.arrayOf(MarkerPropType).isRequired,
  /**
   * The list of amenities placed on this map as markers.
   */
  amenities: PropTypes.arrayOf(MarkerPropType).isRequired,
}
export const MapFloorPlatePropType = PropTypes.shape(MapFloorPlatePropTypeDef)

/**
 * A structure that describes the floors and markers in a single building.
 * @typedef MapBuildingPropType - In addition to the "floors" and "noFloor"
 * key, this structure also includes keys for each floor id.
 * @type {Object.<string, MapFloorPlatePropType>}
 * @property {MapFloorPlatePropType[]} floors
 * @property {MapFloorPlatePropType} noFloor
 */
export const MapBuildingPropTypeDef = {
  /**
   * An array of the of the floors in a building, appropriately sorted.
   */
  floors: PropTypes.arrayOf(MapFloorPlatePropType).isRequired,
  /**
   * All of the markers that do not have a floorPlate.
   */
  noFloor: MapFloorPlatePropType,
  //...
  // 4: MapFloorPlatePropType,
  // 7: MapFloorPlatePropType
}
export const MapBuildingPropType = PropTypes.shape(MapBuildingPropTypeDef)

/**
 * A data structure with everything needed to construct a tour
 * page including the tour stops grouped by building, grouped by floor
 * and as a flat list of markers.
 */
export const TourPropTypeDef = {
  buildings: PropTypes.arrayOf(MapBuildingPropType),
  ungrouped: MapFloorPlatePropType,
  markers: PropTypes.arrayOf(MarkerPropType),
  floors: PropTypes.arrayOf(MapFloorPlatePropType),
}
export const TourPropType = PropTypes.shape(TourPropTypeDef)
