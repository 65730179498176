export interface DoorCodeParseResult {
  /**
   * The alpha numeric portion of the door code.
   */
  code: string
  /**
   * The non-alpha numeric special character at the beginning/end of the code.
   * If there is no special character, this will be undefined.
   */
  char: string | undefined
  /**
   * Where in the door code the special character was found, if at all.
   */
  position: 'start' | 'end' | undefined
}

/**
 * Parse a door code with an alpha numeric code which may have a special
 * character prefix or suffix into a structured object. The result gives you the
 * alpha numeric code, the special character (if any), and whether it was at the
 * start or end of the code.
 */
export function parseDoorCode(input: string): DoorCodeParseResult {
  const isAlphanumeric = (char: string) => /^[a-zA-Z0-9]$/.test(char)
  let result: DoorCodeParseResult = {
    code: input,
    char: undefined,
    position: undefined,
  }

  if (input.length === 0) {
    return result
  }

  if (!isAlphanumeric(input[0])) {
    result.char = input[0]
    result.code = input.slice(1)
    result.position = 'start'
  } else if (!isAlphanumeric(input[input.length - 1])) {
    result.char = input[input.length - 1]
    result.code = input.slice(0, -1)
    result.position = 'end'
  }

  return result
}
