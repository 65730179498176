import React from 'react'
import ReactModal from 'react-modal'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'

import { combineClasses } from '~/util'
import { ActionProps, CloseAction } from '../buttons'

import styles from './Modal.module.scss'

const animationSpeed = Number(styles.animationSpeed.split('ms')[0]) || 300

// Setting the app element is already taken care of by
// the app but exposing it for easy use in storybook stories.
export function setAppElement(el: HTMLElement | string) {
  ReactModal.setAppElement(el)
}

export interface ModalProps
  extends React.ComponentPropsWithoutRef<typeof ReactModal> {
  'data-testid'?: string
  setIsOpen?: (isOpen: boolean) => void
  closeButtonClassName?: string
  className?: string
  overlayClassName?: string
  label?: string
  closeOnBackgroundClick?: boolean
  light?: boolean
  /**
   * Determins the layout of the modal content.
   *
   * - fill: The content will `flex: 1` to fill the content area below the close
   *   button. The content area has some padding so your content won't hide the
   *   modal overlay area allowing the user to see what's behind the modal. This
   *   is the default layout.
   * - scrollable: The content is centered in the content area but will scroll
   *   if necessary.
   * - fullscreen: The content will fill the entire modal area. This forces the
   *   close button to float above the content and gives you full control of the
   *   modal content area.
   */
  layout?: 'fullscreen' | 'fill' | 'scrollable'
  lockBodyScroll?: boolean
  children: React.ReactNode
  analytics?: {
    category?: ActionProps['category']
    action?: ActionProps['action']
    label?: ActionProps['label']
    value?: ActionProps['value']
  }
  /**
   * Disable the CSS entrance/exit animations. This is used by the Persona Modal
   * which needs to control the visibility of the modal content itself.
   */
  disableAnimations?: boolean
}

export function Modal({
  'data-testid': tid,
  testId = tid,
  isOpen,
  setIsOpen,
  onAfterOpen,
  onAfterClose,
  className,
  overlayClassName,
  closeButtonClassName,
  label,
  closeOnBackgroundClick,
  analytics = {},
  light,
  layout = 'fill',
  lockBodyScroll = true,
  disableAnimations,
  children,
  ...rest
}: ModalProps) {
  const handleClose = () => {
    setIsOpen && setIsOpen(false)
    // For now we call this immediately but eventually it
    // should be called after animating out.
    onAfterClose && onAfterClose()
  }

  // Ensure that the background page does not scroll.
  useLockBodyScroll(lockBodyScroll && isOpen)

  return (
    <ReactModal
      testId={testId}
      className={combineClasses(
        styles.Modal,
        light === true ? styles.whiteBackGround : ''
      )}
      overlayClassName={{
        base: combineClasses(
          styles.overlay,
          light === true && styles.blackText,
          styles[layout],
          !disableAnimations && styles.animated,
          overlayClassName,
          // Used by tests to find the overlay.
          'ReactModal__Overlay'
        ),
        afterOpen: styles.afterOpen,
        beforeClose: styles.beforeClose,
      }}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      contentLabel={label}
      closeTimeoutMS={animationSpeed}
      {...rest}
    >
      <div
        data-testid="Modal"
        className={styles.backdrop}
        onClick={() => closeOnBackgroundClick && handleClose()}
      >
        {setIsOpen && (
          <CloseAction
            data-testid="closeButton"
            button
            transparent
            size="l"
            feel="link"
            className={styles.closeButton}
            onClick={handleClose}
            {...analytics}
          />
        )}
        <div className={styles.contentScroll}>
          <div className={combineClasses(styles.contentWrapper, className)}>
            {children}
          </div>
        </div>
      </div>
    </ReactModal>
  )
}
