import React from 'react'
import { useMedia } from 'react-use'
import { color } from 'console-log-colors'

import styles from './Tour.module.scss'
import { combineClasses, logFactory } from '~/util'
import { ExpandingMap } from '../map'
import { TourSteps } from './tour-steps'
import { PricingDisclosure } from '../pricing-disclosure'
import { DESTINATION_TYPES } from '~/store'
import { type TourBuildings, type Floor, type ID } from '~/service'
import { NotFound } from '../not-found'

const logger = logFactory('Tour', color.bold.greenBright)

const wideBreakpoint = Number(styles.wideBreakpoint.replace('px', '')) || 750

interface EmptyTourProps {}

/**
 * `<EmptyTour>` is displayed when there are no steps on the tour.
 */
export function EmptyTour({ ...rest }: EmptyTourProps) {
  return (
    <div data-testid="EmptyTour" className={styles.EmptyTour} {...rest}>
      <NotFound title="This Tour Is Empty" />
    </div>
  )
}

export interface TourProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onLockError'> {
  /**
   * Get the URL to an amenity page.
   */
  getAmenityURL: (id: ID) => string
  /**
   * Get the URL to a unit page.
   */
  getUnitURL: (id: ID) => string
  /**
   * Whether to show unit prices on the unit cards in the tour steps.
   */
  showUnitPrices?: boolean
  /**
   * The list of buildings on the tour.
   */
  maps?: Floor[]
  /**
   * The list of floor maps to show in the expanding map.
   */
  stops?: TourBuildings
  /**
   * A callback for when a lock fails to open.
   * @param name The name of the lock that failed to open.
   */
  onLockError: (name: string, error: unknown) => void
  /**
   * Additional content to show above/below the map (depending on the device
   * width).
   */
  children?: React.ReactNode
  /**
   * Whether to use the Engrain mapping technology or our own.
   */
  useEngrainMaps?: boolean
  engrainMapId?: string
  /**
   * A callback for when the map has loaded the first map image.
   */
  // TODO Rename onMapReady
  mapReady?: () => void
  /**
   * Whether or not to show prices on model units?
   */
  isShowPriceOnModel?: boolean
  /**
   * Whether animations should be run.
   */
  animated?: boolean
}

/**
 * `Tour` component displays a map of the community with units and amenities
 * that the user would like to visit.
 */
export function Tour({
  getAmenityURL,
  getUnitURL,
  showUnitPrices,
  maps,
  stops,
  onLockError,
  children,
  useEngrainMaps,
  engrainMapId,
  mapReady,
  animated,
  isShowPriceOnModel,
  className,
  ...rest
}: TourProps) {
  const mapRef = React.useRef<HTMLDivElement>(null)
  const mapContainerRef = React.useRef<HTMLDivElement>(null)

  const isWide = useMedia(`(min-width: ${wideBreakpoint}px)`)

  React.useEffect(() => {
    if (mapContainerRef.current && mapRef.current) {
      if (!isWide) {
        // mapRef.current!.style.height = '40vh'
        mapRef.current!.style.height = 'unset'
      } else {
        const listener: IntersectionObserverCallback = (entries) => {
          entries.forEach((entry) => {
            mapRef.current!.style.height = `${entry.intersectionRect.height}px`
          })
        }

        const observer = new IntersectionObserver(listener, {
          root: null,
          threshold: Array.from({ length: 1000 }, (_, i) => i / 1000),
        })

        observer.observe(mapContainerRef.current)

        return () => observer.disconnect()
      }
    } else {
      if (!mapRef.current)
        logger.log('Unable to find a reference to the map element.', color.red)
      if (!mapContainerRef.current)
        logger.log(
          'Unable to find a reference to the map container.',
          color.red
        )
    }
  }, [isWide])

  if (!maps?.length && !stops?.markers?.length) return <EmptyTour />

  const hasUnits = !!stops?.markers?.find(
    (c: { type: string }) => c.type === DESTINATION_TYPES.UNIT
  )

  return (
    <div
      className={combineClasses(styles.Tour, className)}
      data-testid="Tour"
      {...rest}
    >
      {stops && children && (
        <div data-testid="topHeader" className={styles.topHeader}>
          {children}
        </div>
      )}
      {!!maps?.length && (
        // TODO Test no maps
        <div className={styles.mapContainer} ref={mapContainerRef}>
          <ExpandingMap
            className={combineClasses(styles.ExpandingMap, 'full-width')}
            maps={maps}
            useEngrain={useEngrainMaps}
            engrainMapId={engrainMapId}
            onMapReady={mapReady}
            getUnitURL={getUnitURL}
            getAmenityURL={getAmenityURL}
            animated={animated}
            ref={mapRef}
          />
        </div>
      )}
      {stops && (
        // This has to be full width for the TourSteps sticky
        // headers to fully cover the cards.
        <div className={combineClasses(styles.content, 'full-width')}>
          <TourSteps
            stops={stops}
            onLockError={onLockError}
            showUnitPrices={showUnitPrices}
            getUnitURL={getUnitURL}
            getAmenityURL={getAmenityURL}
            stickyStepHeaderClassName={styles.stickyStepHeader}
            isShowPriceOnModel={isShowPriceOnModel}
          />
          {hasUnits && (
            <PricingDisclosure className={styles.PricingDisclosure} />
          )}
        </div>
      )}
    </div>
  )
}
