import React from 'react'

import { StringContaining, formatPhoneNumberURL } from '~/util'

import { ANALYTICS_CATEGORIES, CommunityConfig } from '~/service'
import { CallAction, EmailAction, SMSAction } from '../buttons'

import styles from './CommunityContactLink.module.scss'

/**
 * Determine the contact method that provides the fasted feedback.
 */
function getFastestCommunityContactMethod(communityConfig: CommunityConfig) {
  if (communityConfig.voicePhoneNumber) {
    return {
      action: 'call',
      name: 'Call',
      data: communityConfig.voicePhoneNumber,
    }
  } else if (communityConfig.smsPhoneNumber) {
    return {
      action: 'text',
      name: 'Text',
      data: communityConfig.smsPhoneNumber,
    }
    // TODO chat
    // } else if (communityConfig.chatBotUrl) {
    //   return {
    //     action: 'chat',
    //     name: 'Message',
    //     data: communityConfig.chatBotUrl,
    //   }
  } else if (communityConfig.email) {
    return {
      action: 'email',
      name: 'Email',
      data: communityConfig.email,
    }
  } else {
    return {
      action: 'none',
      name: '',
      data: '',
    }
  }
}

function getCommunityContactLink(
  communityConfig: CommunityConfig,
  analyticsLabel: string,
  capitalizeFirstLetter: boolean
) {
  const contactMethod = getFastestCommunityContactMethod(communityConfig)
  const action = capitalizeFirstLetter
    ? contactMethod.name
    : contactMethod.name.toLowerCase()

  switch (contactMethod.action) {
    case 'call':
      return (
        <>
          {action} us at{' '}
          <CallAction
            href={formatPhoneNumberURL(contactMethod.data, 'tel')}
            unrouted
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Call Community"
            label={analyticsLabel}
          >
            {contactMethod.data}
          </CallAction>
        </>
      )
    case 'text':
      return (
        <>
          {action} us at{' '}
          <SMSAction
            href={formatPhoneNumberURL(contactMethod.data, 'sms')}
            unrouted
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Text Community"
            label={contactMethod.data}
          >
            {contactMethod.data}
          </SMSAction>
        </>
      )
    // TODO: Chat modal (requires moving the Chat component out of /pages/main)
    // case 'chat':
    //   return (
    //     <>
    //       {action} us at{' '}
    //         <Chat
    //           data-testid="chatButton"
    //           className={styles.contactButton}
    //           chatURL={chatURL}
    //           pureChatId={pureChatId}
    //           usePureChat={usePureChat}
    //           pureChatClient={pureChatClient}
    //           pureChatDelay={pureChatDelay}
    //         />
    //     </>
    //   )
    case 'email':
      return (
        <>
          {action} us at{' '}
          <EmailAction
            href={`mailto:${contactMethod.data}`}
            blank
            category={ANALYTICS_CATEGORIES.CONTACT}
            action="Email Community"
            label={analyticsLabel}
          >
            {contactMethod.data}
          </EmailAction>
        </>
      )
    default:
      return null
  }
}

export interface CommunityContactLinkProps {
  communityConfig: CommunityConfig
  /**
   * This should indicate where the contact link was presented and why. For
   * example, "Lock Device Unlock Error Toast".
   */
  analyticsLabel: string
  /**
   * The message in which to include the contact link. Use %link% to indicate
   * where the link should be inserted. `%link%` will be replaced with the some
   * text describing the action as well as the link element.
   * For example, "Please %link% for help." will be replaced with "Please call
   * us at </a>555-555-5555<a> for help."
   *
   */
  message: StringContaining<'%link%'>
}

/**
 * Get an HTML string with a link that allows contacting the community.
 * The link will be either a phone number, SMS number, or email address.
 */
export const CommunityContactLink = React.memo(
  ({ communityConfig, analyticsLabel, message }: CommunityContactLinkProps) => {
    const stringParts = message.split('%link%')
    const front = stringParts[0] // might be ''
    const back = stringParts[1] // might be ''

    const contactLink = getCommunityContactLink(
      communityConfig,
      analyticsLabel,
      // If the link is inserted at the beginning, then capitalize the first letter.
      message.indexOf('%link%') === 0
    )
    return (
      <span className={styles.CommunityContactLink}>
        {front}
        {contactLink}
        {back}
      </span>
    )
  }
)
